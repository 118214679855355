import PageWrapper from "../home/PageWrapper/PageWrapper";
import Services from "./Services";
import { Helmet } from "react-helmet";

export default function Component() {
  return (
    <PageWrapper>
      <Helmet>
        <title>Imaginate Growth Agency - Services</title>
      </Helmet>
      <Services />
    </PageWrapper>
  );
}
