import PageWrapper from "../PageWrapper/PageWrapper";
import Homepage from "./HomePage";
import { Helmet } from "react-helmet";

export default function Component() {
  return (
    <PageWrapper>
      <Helmet>
        <title>Imaginate Growth Agency</title>
      </Helmet>
      <Homepage />
    </PageWrapper>
  );
}
