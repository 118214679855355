import { Box, Typography, Button, Grid2 as Grid } from "@mui/material";
import FixedWidthContainer from "../../../reuseable/FixedWidthContainer/FixedWidthContainer";
import { MechanicalCounter } from "mechanical-counter";
import "./Homepage.css";
import { useEffect, useState } from "react";
import "./particles";

export default function Homepage() {
  const messages = [
    {
      prefix: "+",
      metric: "59.22",
      suffix: "%",
      message: "Average return on investment",
    },
    {
      prefix: "$",
      metric: "82",
      suffix: "M",
      message: "Ads Managed",
    },
    {
      prefix: "+",
      metric: "42.37",
      suffix: "%",
      message: "Return On Ad Spend",
    },
    {
      prefix: "",
      metric: "100",
      suffix: "%",
      message: "customer satisfaction",
    },
  ];
  const [messageIndex, setMessageIndex] = useState(0);

  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      if (messageIndex + 1 < messages.length) {
        setMessageIndex(messageIndex + 1);
      } else {
        setMessageIndex(0);
      }
    }, 3000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [messageIndex]);

  useEffect(() => {
    const load = () => {
      window.particlesJS(
        "particles-js",

        {
          particles: {
            number: {
              value: 80,
              density: {
                enable: true,
                value_area: 800,
              },
            },
            color: {
              value: "#ffffff",
            },
            opacity: {
              value: 0.5,
              random: false,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false,
              },
            },
            size: {
              value: 5,
              random: true,
              anim: {
                enable: false,
                speed: 20,
                size_min: 10,
                sync: false,
              },
            },
            line_linked: {
              enable: true,
              distance: 150,
              color: "#ffffff",
              opacity: 0.4,
              width: 1,
            },
            move: {
              enable: true,
              speed: 6,
              direction: "none",
              random: false,
              straight: false,
              out_mode: "out",
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200,
              },
            },
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: {
                enable: true,
                mode: "repulse",
              },
              onclick: {
                enable: true,
                mode: "push",
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 400,
                size: 40,
                duration: 2,
                opacity: 8,
                speed: 3,
              },
              repulse: {
                distance: 180,
              },
              push: {
                particles_nb: 4,
              },
              remove: {
                particles_nb: 2,
              },
            },
          },
          retina_detect: true,
        }
      );
    };

    setTimeout(() => {
      load();
    }, 1);
  }, []);

  const handleSalesCall = () => {
    window.location = "https://calendly.com/imaginate-io/30min";
  };

  return (
    <>
      <Box className="syn-homepage-hero">
        <Box backgroundColor="black" width="100%">
          <div id="particles-js" style={{ height: "100%" }}></div>
          <Box
            position="absolute"
            top="50%"
            left="50%"
            marginLeft="-48px"
            zIndex="12"
            color="white"
            style={{ transform: "scale(3) translate3d(0,0,0)" }}
          >
            <Box
              fontFamily='"Nunito", serif;'
              letterSpacing="2px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              {messages[messageIndex].prefix}
              <MechanicalCounter
                text={
                  messages[messageIndex] ? messages[messageIndex].metric : "0"
                }
                style={{ display: "inline-block" }}
              />
              {messages[messageIndex].suffix}
            </Box>
            <Box fontSize="5px" textAlign="center">
              {messages[messageIndex].message}
            </Box>
            <Button
              style={{ transform: "scale(0.3)" }}
              onClick={handleSalesCall}
              variant="outlined"
            >
              Book Now
            </Button>
          </Box>
        </Box>
      </Box>

      <Box>
        <Box p="4em">
          <FixedWidthContainer>
            <Typography
              variant="h6"
              fontFamily='"Nunito", serif;'
              gutterBottom
              sx={{
                lineHeight: "32px",
                marginBottom: "2em",
                textTransform: "uppercase",
                color: "#555",
                letterSpacing: "2px",
              }}
            >
              What we do
            </Typography>
            <Typography sx={{ lineHeight: "32px" }}>
              <div>Professional marketing consulting.</div>
              <div>
                We specialize in growing online brands, ecommerce, and SaaS
                software companies. Our objective is to support your growth in
                developing your business in creating the narrative to capture
                quality customers.
              </div>

              <div>
                We run paid ads, manage your advertising campaigns, email
                marketing, brand consulting, with a focus on key growth metrics
                for your business.
              </div>
            </Typography>
            <Button
              style={{ width: { xs: "100%", s: "initial" }, marginTop: "1em" }}
              onClick={handleSalesCall}
              variant="contained"
            >
              BOOK NOW
            </Button>
          </FixedWidthContainer>
        </Box>

        <Box p="4em" backgroundColor="#f2f2f2">
          <FixedWidthContainer>
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, md: 4 }}>
                <Box p="1em">
                  <Typography
                    variant="h6"
                    fontFamily='"Nunito", serif;'
                    sx={{
                      lineHeight: "32px",
                      textTransform: "uppercase",
                      color: "#555",
                      marginBottom: "1em",
                      letterSpacing: "2px",
                    }}
                  >
                    Seminars
                  </Typography>
                  <Box lineHeight="32px" marginBottom="1em" color="#333">
                    Learn from our professional team, we hold weekly seminars to
                    help you develop the skills you need today to take advantage
                    of the internet.
                  </Box>
                  <Button
                    style={{ width: { xs: "100%", s: "initial" } }}
                    onClick={handleSalesCall}
                    variant="outlined"
                  >
                    SIGN UP
                  </Button>
                </Box>
              </Grid>
              <Grid size={{ xs: 12, md: 4 }}>
                <Box p="1em">
                  <Typography
                    variant="h6"
                    fontFamily='"Nunito", serif;'
                    sx={{
                      lineHeight: "32px",
                      textTransform: "uppercase",
                      color: "#555",
                      marginBottom: "1em",
                      letterSpacing: "2px",
                    }}
                  >
                    SaaS
                  </Typography>
                  <Box lineHeight="32px" marginBottom="1em" color="#333">
                    We carry a suite of powerful online software tools to
                    support an entrepeneur along their journey in growth
                    marketing.
                  </Box>
                  <Button
                    style={{ width: { xs: "100%", s: "initial" } }}
                    onClick={handleSalesCall}
                    variant="outlined"
                  >
                    LOG IN
                  </Button>
                </Box>
              </Grid>
              <Grid size={{ xs: 12, md: 4 }}>
                <Box p="1em">
                  <Typography
                    variant="h6"
                    fontFamily='"Nunito", serif;'
                    sx={{
                      lineHeight: "32px",
                      textTransform: "uppercase",
                      color: "#555",
                      marginBottom: "1em",
                      letterSpacing: "2px",
                    }}
                  >
                    Consultation
                  </Typography>
                  <Box lineHeight="32px" marginBottom="1em" color="#333">
                    Take advantage of our team of marketing and software
                    professionals. We will work alongside of you to scale your
                    company at your pace.
                  </Box>
                  <Button
                    style={{ width: { xs: "100%", s: "initial" } }}
                    onClick={handleSalesCall}
                    variant="contained"
                  >
                    Book Now
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </FixedWidthContainer>
        </Box>
      </Box>

      <Box p="1em" backgroundColor="#333333" color="white">
        <FixedWidthContainer>
          <Box
            fontSize="11px"
            fontFamily='"Nunito", serif;'
            textTransform="uppercase"
            letterSpacing="2px"
          >
            &copy; Imaginate Marketing, inc. {new Date().getFullYear()}.
          </Box>
        </FixedWidthContainer>
      </Box>
    </>
  );
}
