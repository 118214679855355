import "./App.css";
import HomePage from "./components/pages/home/HomePage";
import VideoSalesLetterPage from "./components/pages/VideoSalesLetterPage";
import Login from "./components/pages/auth/LoginPage/LoginPage";
import ForgotPasswordPage from "./components/pages/auth/ForgotPasswordPage/ForgotPasswordPage";
import SetPasswordPage from "./components/pages/auth/SetPasswordPage/SetPasswordPage";
import PageNotFound from "./components/pages/PageNotFound/PageNotFound";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CreateAccountPage from "./components/pages/auth/CreateAccountPage/CreateAccountPage";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import OAuthGoogle from "./components/pages/oauth/OAuthGoogle";
import OAuthApple from "./components/pages/oauth/OAuthApple";
import PrivacyPage from "./components/pages/Privacy";
import TermsPage from "./components/pages/Terms";
import InformationPage from "./components/pages/Info/index";
import ServicesPage from "./components/pages/Services";
import CaseStudiesPage from "./components/pages/CaseStudies";

export const themeOptions = {
  typography: {
    fontFamily: '"Nunito", serif;',
    h1: {
      fontWeight: 100,
      textTransform: "uppercase",
      letterSpacing: "2px",
    },
    h2: {
      fontWeight: 100,
      textTransform: "uppercase",
      letterSpacing: "2px",
    },
    h3: {
      fontWeight: 100,
      textTransform: "uppercase",
      letterSpacing: "2px",
    },
    h4: {
      fontWeight: 100,
      textTransform: "uppercase",
      letterSpacing: "2px",
    },
  },
  components: {
    MuiInput: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#1698A5",
      contrastText: "#fff",
    },
    secondary: {
      main: "#1da5a3",
      contrastText: "#fff",
    },
  },
};

const theme = createTheme(themeOptions);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="imaginate">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="vsl" element={<VideoSalesLetterPage />} />
            <Route path="info" element={<InformationPage />} />

            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgotPasswordPage />} />
            <Route path="set-password/:code" element={<SetPasswordPage />} />
            <Route path="create-account" element={<CreateAccountPage />} />
            <Route path="services" element={<ServicesPage />} />
            <Route path="case-studies" element={<CaseStudiesPage />} />
            <Route path="privacy" element={<PrivacyPage />} />
            <Route path="terms" element={<TermsPage />} />

            <Route path="auth">
              <Route path="google" element={<OAuthGoogle />} />
              <Route path="apple" element={<OAuthApple />} />
            </Route>

            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
