import { Box, Typography, Button, Grid2 as Grid } from "@mui/material";
import FixedWidthContainer from "../../reuseable/FixedWidthContainer/FixedWidthContainer";
import "./Services.css";
import { useEffect, useState } from "react";
import "./particles";
import ReadingMailImage from "../../../assets/website/reading-mail.png";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import RobotAnalysis from "./robot-analysis.png";
import LineUpAtStore from "./line-up-store.png";
import Salesmen from "./sales.png";

export default function Services() {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    const load = () => {
      window.particlesJS(
        "particles-js",

        {
          particles: {
            number: {
              value: 80,
              density: {
                enable: true,
                value_area: 800,
              },
            },
            color: {
              value: "#ffffff",
            },
            opacity: {
              value: 0.5,
              random: false,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false,
              },
            },
            size: {
              value: 5,
              random: true,
              anim: {
                enable: false,
                speed: 20,
                size_min: 10,
                sync: false,
              },
            },
            line_linked: {
              enable: true,
              distance: 150,
              color: "#ffffff",
              opacity: 0.4,
              width: 1,
            },
            move: {
              enable: true,
              speed: 6,
              direction: "none",
              random: false,
              straight: false,
              out_mode: "out",
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200,
              },
            },
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: {
                enable: true,
                mode: "repulse",
              },
              onclick: {
                enable: true,
                mode: "push",
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 400,
                size: 40,
                duration: 2,
                opacity: 8,
                speed: 3,
              },
              repulse: {
                distance: 180,
              },
              push: {
                particles_nb: 4,
              },
              remove: {
                particles_nb: 2,
              },
            },
          },
          retina_detect: true,
        }
      );
    };

    setTimeout(() => {
      load();
    }, 1);
  }, []);

  const handleSalesCall = () => {
    window.location = "https://calendly.com/imaginate-io/business-growth-call";
  };

  const steps = [
    {
      id: 0,
      name: "Analyze, Assess, and Consult",
      img: RobotAnalysis,
      description:
        "Do you know what your customers are experiencing on your website or platform? If you do not, we will install our proprietary Imaginate Pixel. Our data collection agent will allow you to understand your customers intimately using the same attribution technology as the largest Ad Tech companies. Once we are aware of what is going on in your business we will consult with you on strategies to scale your business.",
      productName: "Imaginate Pixel Features",
      productFeatures: [
        "See videos of your customers experiencing your product, find out where they are frustrated or where they are dropping off.",
        "Know for certain how many visitors have visited your page and where they have come from. Filter out bots or unwanted visitors.",
        "Pixel is focused on collecting metrics related to modern metrics such as Return On Ad Spend, Customer Acquisition Cost, Lifetime Value of a Customer",
      ],
      nextButtonText: "Next, lead generation",
    },
    {
      id: 1,
      name: "Lead Generation",
      img: LineUpAtStore,
      description:
        "Next, we will examine your lead generation strategy—that is, how you attract and acquire customers. We have three tailored marketing strategies that we will implement depending on the market you serve. Review the strategies below. We will also look into your onboarding strategy as well as your fulfillment process here, to make sure everything in your business is singing.",
      productName: "Marketing Strategies",
      productFeatures: [
        "AI Appointment Setting: This is for B2B. The AI Appointment Setter will take in a list of qualified leads that are interested in your market, and then follow up with them on emails to make sure the leads will respond. After, the AI Agent will make sure the leads are warmed up before booking them onto your calendar.",
        "Personal Branding Strategy: This is for B2C. The personal branding strategy focuses on creating a marketing strategy that is relatable to your existing user base. Our team will work with you to find out the best way to position your product in the ever-changing demands of the consumer market.",
        "Growth Operator Model: This is for Content Creators with over 100K followers. The growth operator model is a marketing strategy that leverages your followers demand to gain access to you.",
      ],
      nextButtonText: "Last, sales process",
    },
    {
      id: 2,
      name: "Sales Process",
      img: Salesmen,
      description:
        "Finally, how well is your sales team performing? Closing deals is crucial for creating new opportunities for your business. Our partner sales organizations specialize in training and placing sales professionals. We also focus on optimizing outbound sales systems for growth because, to us, acquiring a customer is just the beginning of the journey.",
    },
  ];

  return (
    <>
      <Box className="syn-homepage-hero">
        <Box backgroundColor="black" width="100%">
          <div id="particles-js" style={{ height: "100%" }}></div>
          <Box
            position="absolute"
            top="50%"
            left="50%"
            marginLeft="-28px"
            zIndex="1"
            color="white"
            style={{ transform: "scale(3) translate3d(0,0,0)" }}
          >
            <Box
              fontFamily='"Nunito", serif;'
              letterSpacing="2px"
              display="flex"
              alignItems="center"
              fontWeight="100"
              justifyContent="center"
              textAlign="center"
            >
              SERVICES
            </Box>

            <Box
              fontSize="6px"
              fontFamily='"Nunito", serif;'
              textAlign="center"
              mt="1em"
            >
              Learn more about our process
              <br />
              and how we can help you.
            </Box>
          </Box>
        </Box>
      </Box>

      <Box p={{ xs: "4em 1em", sm: "4em" }}>
        <FixedWidthContainer>
          <Typography
            gutterBottom
            sx={{
              mt: 2,
              mb: "2em",
              fontSize: "1.5em",
              textAlign: "center",
            }}
          >
            Our Process
          </Typography>
          <Box sx={{ width: "100%" }}>
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              sx={{ cursor: "pointer" }}
            >
              {steps.map((step) => (
                <Step onClick={() => setActiveStep(step.id)} key={step.name}>
                  <StepLabel>{step.name}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          <Box mt="2em" display={{ xs: "initial", md: "flex" }}>
            <Box textAlign="center">
              <img src={steps[activeStep].img} style={{ width: "200px" }} />
            </Box>
            <Box mt="1em" ml="2em" lineHeight="1.75em">
              {steps[activeStep].description}
              {activeStep < 2 && (
                <Button onClick={() => setActiveStep(activeStep + 1)}>
                  {steps[activeStep].nextButtonText}
                </Button>
              )}
            </Box>
          </Box>
        </FixedWidthContainer>
      </Box>

      <Box p={{ xs: "4em 1em", sm: "4em" }} backgroundColor="#f2f2f2">
        <FixedWidthContainer>
          <Typography
            gutterBottom
            sx={{
              mt: 2,
              fontSize: "1.5em",
              textAlign: "center",
            }}
          >
            {steps[activeStep].productName}
          </Typography>
          <Grid container spacing={2}>
            {(steps[activeStep].productFeatures || []).map((feature) => (
              <Grid
                size={{
                  xs: 12,
                  md: 12 / steps[activeStep].productFeatures.length,
                }}
              >
                <Box p="1em" lineHeight="1.75em">
                  {feature}
                </Box>
              </Grid>
            ))}
          </Grid>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Button onClick={handleSalesCall} variant="outlined">
              Consult with us
            </Button>
          </Box>
        </FixedWidthContainer>
      </Box>

      <Box p={{ xs: "4em 1em", sm: "4em" }}>
        <FixedWidthContainer>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 4 }}>
              <img
                src={ReadingMailImage}
                style={{ width: "100%", maxWidth: "300px" }}
              />
            </Grid>

            <Grid size={{ xs: 12, md: 8 }}>
              <Typography
                variant="h6"
                fontFamily='"Nunito", serif;'
                gutterBottom
                sx={{
                  lineHeight: "32px",
                  marginBottom: "2em",
                  textTransform: "uppercase",
                  color: "#555",
                  letterSpacing: "2px",
                }}
              >
                Keep in touch
              </Typography>
              <Box lineHeight="32px" marginBottom="1em" color="#333">
                Stay connected with us. Enter your email to discover more about
                our services and updates. Or, sign up to keep updated with tips
                on how to grow your business.
              </Box>

              <Box ml="-1em">
                <div id="mc_embed_shell">
                  <div id="mc_embed_signup">
                    <form
                      action="https://imaginate.us11.list-manage.com/subscribe/post?u=d90b0fa4f4bc1cd0fea68f9d2&amp;id=9bb8445736&amp;f_id=003418e0f0"
                      method="post"
                      id="mc-embedded-subscribe-form"
                      name="mc-embedded-subscribe-form"
                      class="validate"
                      target="_blank"
                    >
                      <div id="mc_embed_signup_scroll">
                        <div class="mc-field-group">
                          <input
                            placeholder="Email Address"
                            type="email"
                            name="EMAIL"
                            class="required email"
                            id="mce-EMAIL"
                            required=""
                          />
                        </div>
                        <div id="mce-responses" class="clear">
                          <div
                            class="response"
                            id="mce-error-response"
                            style={{ display: "none" }}
                          ></div>
                          <div
                            class="response"
                            id="mce-success-response"
                            style={{ display: "none" }}
                          ></div>
                        </div>
                        <div
                          aria-hidden="true"
                          style={{ position: "absolute", left: "-5000px" }}
                        >
                          <input
                            type="text"
                            value=""
                            name="b_d90b0fa4f4bc1cd0fea68f9d2_9bb8445736"
                            tabindex="-1"
                          />
                        </div>
                        <div class="clear">
                          <input
                            type="submit"
                            name="subscribe"
                            id="mc-embedded-subscribe"
                            class="button"
                            value="Subscribe"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </Box>
            </Grid>
          </Grid>
        </FixedWidthContainer>
      </Box>
    </>
  );
}
