import "./App.css";
import HomePage from "./components/pages/home/HomePage";
import Login from "./components/pages/auth/LoginPage/LoginPage";
import ForgotPasswordPage from "./components/pages/auth/ForgotPasswordPage/ForgotPasswordPage";
import SetPasswordPage from "./components/pages/auth/SetPasswordPage/SetPasswordPage";
import PageNotFound from "./components/pages/PageNotFound/PageNotFound";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CreateAccountPage from "./components/pages/auth/CreateAccountPage/CreateAccountPage";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import OAuthGoogle from "./components/pages/oauth/OAuthGoogle";
import OAuthApple from "./components/pages/oauth/OAuthApple";

export const themeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#24b772",
      contrastText: "#fff",
    },
    secondary: {
      main: "#4FC58E",
      contrastText: "#fff",
    },
  },
};

const theme = createTheme(themeOptions);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="synclis">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />

            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgotPasswordPage />} />
            <Route path="set-password/:code" element={<SetPasswordPage />} />
            <Route path="create-account" element={<CreateAccountPage />} />

            <Route path="auth">
              <Route path="google" element={<OAuthGoogle />} />
              <Route path="apple" element={<OAuthApple />} />
            </Route>

            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
