// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.syn-homepage-hero {
  display: flex;
  position: relative;
  overflow: hidden;
  height: 400px;
}
.syn-homepage-hero img {
  display: none;
}

@media (max-width: 800px) {
  .syn-homepage-hero video {
    display: none;
  }

  .syn-homepage-hero img {
    position: relative;
    top: -200px;
    display: initial;
    width: 100%;
    height: fit-content;
  }
}

@media (max-width: 450px) {
  .syn-homepage-hero img {
    position: relative;
    top: 0;
    display: initial;
    width: 100%;
    height: fit-content;
  }
}

#mc_embed_signup {
  background: #fff;
  clear: left;
  font: 14px Helvetica, Arial, sans-serif;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/home/HomePage/Homepage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,aAAa;AACf;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,WAAW;IACX,mBAAmB;EACrB;AACF;;AAEA;EACE;IACE,kBAAkB;IAClB,MAAM;IACN,gBAAgB;IAChB,WAAW;IACX,mBAAmB;EACrB;AACF;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,uCAAuC;EACvC,WAAW;AACb","sourcesContent":[".syn-homepage-hero {\n  display: flex;\n  position: relative;\n  overflow: hidden;\n  height: 400px;\n}\n.syn-homepage-hero img {\n  display: none;\n}\n\n@media (max-width: 800px) {\n  .syn-homepage-hero video {\n    display: none;\n  }\n\n  .syn-homepage-hero img {\n    position: relative;\n    top: -200px;\n    display: initial;\n    width: 100%;\n    height: fit-content;\n  }\n}\n\n@media (max-width: 450px) {\n  .syn-homepage-hero img {\n    position: relative;\n    top: 0;\n    display: initial;\n    width: 100%;\n    height: fit-content;\n  }\n}\n\n#mc_embed_signup {\n  background: #fff;\n  clear: left;\n  font: 14px Helvetica, Arial, sans-serif;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
