import FixedWidthContainer from "../../../reuseable/FixedWidthContainer/FixedWidthContainer";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <>
      <Box p="1em" backgroundColor="#333333" color="white">
        <FixedWidthContainer>
          <Box display="flex" justifyContent="space-between">
            <Box
              fontSize="11px"
              fontFamily='"Nunito", serif;'
              textTransform="uppercase"
              letterSpacing="2px"
            >
              &copy; Imaginate, inc. {new Date().getFullYear()}.
            </Box>
            <Box
              fontFamily='"Nunito", serif;'
              fontSize="12px"
              textTransform="uppercase"
              display="flex"
              letterSpacing="2px"
              gap="8px"
            >
              <Link
                to="/privacy"
                style={{
                  cursor: "pointer",
                  color: "#1698A5",
                  textDecoration: "none",
                }}
              >
                Privacy
              </Link>
              |
              <Link
                to="/terms"
                style={{
                  cursor: "pointer",
                  color: "#1698A5",
                  textDecoration: "none",
                }}
              >
                Terms
              </Link>
            </Box>
          </Box>
        </FixedWidthContainer>
      </Box>
    </>
  );
}
