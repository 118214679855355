import PageWrapper from "../home/PageWrapper/PageWrapper";
import CaseStudies from "./CaseStudies";
import { Helmet } from "react-helmet";

export default function Component() {
  return (
    <PageWrapper>
      <Helmet>
        <title>Imaginate Growth Agency - Case Studies</title>
      </Helmet>
      <CaseStudies />
    </PageWrapper>
  );
}
