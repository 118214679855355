import { Box, Typography, Button, Grid2 as Grid } from "@mui/material";
import FixedWidthContainer from "../../../reuseable/FixedWidthContainer/FixedWidthContainer";
import { MechanicalCounter } from "mechanical-counter";
import "./Homepage.css";
import { useEffect, useState } from "react";
import "./particles";
import ReadingMailImage from "../../../../assets/website/reading-mail.png";
import ProsShakeHandsImage from "../../../../assets/website/professional-shake.png";
import ConsultingImage from "../../../../assets/website/consulting.png";

export default function Homepage() {
  const messages = [
    {
      prefix: "+",
      metric: "419.27",
      suffix: "%",
      message: "Average return on investment",
    },
    {
      prefix: "$",
      metric: "16,000,000",
      suffix: "+",
      message: "Ads Managed",
    },
    {
      prefix: "+",
      metric: "4.31",
      suffix: "",
      message: "Average Return On Ad Spend",
    },
    {
      prefix: "",
      metric: "100",
      suffix: "%",
      message: "customer satisfaction",
    },
  ];
  const [messageIndex, setMessageIndex] = useState(0);

  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      if (messageIndex + 1 < messages.length) {
        setMessageIndex(messageIndex + 1);
      } else {
        setMessageIndex(0);
      }
    }, 3000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [messageIndex]);

  useEffect(() => {
    const load = () => {
      window.particlesJS(
        "particles-js",

        {
          particles: {
            number: {
              value: 80,
              density: {
                enable: true,
                value_area: 800,
              },
            },
            color: {
              value: "#ffffff",
            },
            opacity: {
              value: 0.5,
              random: false,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false,
              },
            },
            size: {
              value: 5,
              random: true,
              anim: {
                enable: false,
                speed: 20,
                size_min: 10,
                sync: false,
              },
            },
            line_linked: {
              enable: true,
              distance: 150,
              color: "#ffffff",
              opacity: 0.4,
              width: 1,
            },
            move: {
              enable: true,
              speed: 6,
              direction: "none",
              random: false,
              straight: false,
              out_mode: "out",
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200,
              },
            },
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: {
                enable: true,
                mode: "repulse",
              },
              onclick: {
                enable: true,
                mode: "push",
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 400,
                size: 40,
                duration: 2,
                opacity: 8,
                speed: 3,
              },
              repulse: {
                distance: 180,
              },
              push: {
                particles_nb: 4,
              },
              remove: {
                particles_nb: 2,
              },
            },
          },
          retina_detect: true,
        }
      );
    };

    setTimeout(() => {
      load();
    }, 1);
  }, []);

  const handleSalesCall = () => {
    window.location = "https://calendly.com/imaginate-io/business-growth-call";
  };

  const handleServicePage = () => {
    window.location = "/services";
  };

  return (
    <>
      <Box className="syn-homepage-hero">
        <Box backgroundColor="black" width="100%">
          <div id="particles-js" style={{ height: "100%" }}></div>
          <Box
            position="absolute"
            top="50%"
            left="50%"
            marginLeft="-88px"
            zIndex="12"
            color="white"
            style={{ transform: "scale(3) translate3d(0,0,0)" }}
          >
            <Box
              fontFamily='"Nunito", serif;'
              letterSpacing="2px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              {messages[messageIndex].prefix}
              <MechanicalCounter
                text={
                  messages[messageIndex] ? messages[messageIndex].metric : "0"
                }
                style={{ display: "inline-block" }}
              />
              {messages[messageIndex].suffix}
            </Box>
            <Box fontSize="5px" textAlign="center">
              {messages[messageIndex].message}
            </Box>
            <Button
              style={{ transform: "scale(0.3)" }}
              onClick={handleSalesCall}
              variant="outlined"
            >
              Consult with us
            </Button>
          </Box>
        </Box>
      </Box>

      <Box p={{ xs: "4em 1em", sm: "4em" }}>
        <FixedWidthContainer>
          <Box p="0 1em">
            <Typography
              variant="h6"
              fontFamily='"Nunito", serif;'
              gutterBottom
              sx={{
                lineHeight: "32px",
                marginBottom: "2em",
                textTransform: "uppercase",
                color: "#555",
                letterSpacing: "2px",
              }}
            >
              What we do
            </Typography>
            <Typography sx={{ lineHeight: "32px" }}>
              <Box mb="4em">
                <p>
                  We specialize in growing and scaling your online business.
                </p>
                <p>
                  Having trouble with growth in your business or managing the
                  rate of growth in your business? We take a holistic approach,
                  by first installing our Pixel to analyze your key metrics such
                  as Customer Acquisition Cost, Lifetime Value of a Customer,
                  and Return on Ad Spend. After, we will create and execute a
                  strategy looking into any sales, marketing and advertising
                  concerns in your business.{" "}
                  <Button onClick={handleServicePage}>Learn More</Button>
                </p>
                <p>We generally work with:</p>
              </Box>
            </Typography>
          </Box>

          <Grid container spacing={4}>
            <Grid size={{ xs: 12, md: 6 }}>
              <Box p="1em">
                <img
                  src={ConsultingImage}
                  style={{ width: "200px", marginBottom: "1em" }}
                />
                <Typography
                  variant="h6"
                  fontFamily='"Nunito", serif;'
                  sx={{
                    lineHeight: "32px",
                    textTransform: "uppercase",
                    color: "#555",
                    marginBottom: "1em",
                    letterSpacing: "2px",
                  }}
                >
                  Startup Businesses
                </Typography>
                <Box lineHeight="32px" marginBottom="1em" color="#333">
                  We are interested in working with your new business. Our team
                  of marketing experts will cut through the grind while we focus
                  on the high level. If selected, I will partner with you,
                  coaching you through all of the challenges of building an
                  online business. When working with us you can expect to start
                  earning revenue within 30 days.
                </Box>
              </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Box p="1em">
                <img
                  src={ProsShakeHandsImage}
                  style={{ width: "200px", marginBottom: "1em" }}
                />
                <Typography
                  variant="h6"
                  fontFamily='"Nunito", serif;'
                  sx={{
                    lineHeight: "32px",
                    textTransform: "uppercase",
                    color: "#555",
                    marginBottom: "1em",
                    letterSpacing: "2px",
                  }}
                >
                  Established Businesses
                </Typography>
                <Box lineHeight="32px" marginBottom="1em" color="#333">
                  Are you an online businesses having trouble growing? We will
                  consult with you and install our analytics framework to
                  leverage data to find out where your problem areas are. Your
                  Growth Advisor will work with you one on one, coaching you
                  through all of the challenges of resolving the issues of
                  online business.
                </Box>
              </Box>
            </Grid>
          </Grid>
        </FixedWidthContainer>
      </Box>

      <Box p={{ xs: "4em 1em", sm: "4em" }} backgroundColor="#f2f2f2">
        <FixedWidthContainer>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 4 }}>
              <Box p="1em">
                <Typography
                  variant="h6"
                  fontFamily='"Nunito", serif;'
                  sx={{
                    lineHeight: "32px",
                    textTransform: "uppercase",
                    color: "#555",
                    marginBottom: "1em",
                    letterSpacing: "2px",
                  }}
                >
                  Academy
                </Typography>
                <Box lineHeight="32px" marginBottom="1em" color="#333">
                  Invest in yourself. We offer business education in our
                  academy, it contains information to get your business off the
                  ground at no cost to you. Our courses will help you learn the
                  skills you need to be successful on the internet.
                </Box>
                <Button
                  style={{ width: { xs: "100%", s: "initial" } }}
                  onClick={() => {
                    window.location = "https://www.skool.com/imaginate";
                  }}
                  variant="outlined"
                >
                  Register
                </Button>
              </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
              <Box p="1em">
                <Typography
                  variant="h6"
                  fontFamily='"Nunito", serif;'
                  sx={{
                    lineHeight: "32px",
                    textTransform: "uppercase",
                    color: "#555",
                    marginBottom: "1em",
                    letterSpacing: "2px",
                  }}
                >
                  Consultation
                </Typography>
                <Box lineHeight="32px" marginBottom="1em" color="#333">
                  Invest in your business. Learn more about what our team can do
                  for you. Please only consult with us if you're an
                  <b> established business</b> needing help with growth or a
                  <b> new online business</b> looking to partner with us.
                </Box>
                <Button
                  style={{ width: { xs: "100%", s: "initial" } }}
                  onClick={handleSalesCall}
                  variant="contained"
                >
                  Consult with us
                </Button>
              </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
              <Box p="1em">
                <Typography
                  variant="h6"
                  fontFamily='"Nunito", serif;'
                  sx={{
                    lineHeight: "32px",
                    textTransform: "uppercase",
                    color: "#555",
                    marginBottom: "1em",
                    letterSpacing: "2px",
                  }}
                >
                  Acquisition
                </Typography>
                <Box lineHeight="32px" marginBottom="1em" color="#333">
                  We are interested in acquiring your small cash-flow positive
                  business.
                </Box>
                <Button
                  style={{ width: { xs: "100%", s: "initial" } }}
                  onClick={() => {
                    window.location =
                      "https://calendly.com/imaginate-io/business-acquisition";
                  }}
                  variant="outlined"
                >
                  Speak with us
                </Button>
              </Box>
            </Grid>
          </Grid>
        </FixedWidthContainer>
      </Box>

      <Box p={{ xs: "4em 1em", sm: "4em" }}>
        <FixedWidthContainer>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 4 }}>
              <img
                src={ReadingMailImage}
                style={{ width: "100%", maxWidth: "300px" }}
              />
            </Grid>

            <Grid size={{ xs: 12, md: 8 }}>
              <Typography
                variant="h6"
                fontFamily='"Nunito", serif;'
                gutterBottom
                sx={{
                  lineHeight: "32px",
                  marginBottom: "2em",
                  textTransform: "uppercase",
                  color: "#555",
                  letterSpacing: "2px",
                }}
              >
                Keep in touch
              </Typography>
              <Box lineHeight="32px" marginBottom="1em" color="#333">
                Stay connected with us. Enter your email to discover more about
                our services and updates. Or, sign up to keep updated with tips
                on how to grow your business.
              </Box>

              <Box ml="-1em">
                <div id="mc_embed_shell">
                  <div id="mc_embed_signup">
                    <form
                      action="https://imaginate.us11.list-manage.com/subscribe/post?u=d90b0fa4f4bc1cd0fea68f9d2&amp;id=9bb8445736&amp;f_id=003418e0f0"
                      method="post"
                      id="mc-embedded-subscribe-form"
                      name="mc-embedded-subscribe-form"
                      className="validate"
                      target="_blank"
                    >
                      <div id="mc_embed_signup_scroll">
                        <div className="mc-field-group">
                          <input
                            placeholder="Email Address"
                            type="email"
                            name="EMAIL"
                            className="required email"
                            id="mce-EMAIL"
                            required=""
                          />
                        </div>
                        <div id="mce-responses" className="clear">
                          <div
                            className="response"
                            id="mce-error-response"
                            style={{ display: "none" }}
                          ></div>
                          <div
                            className="response"
                            id="mce-success-response"
                            style={{ display: "none" }}
                          ></div>
                        </div>
                        <div
                          aria-hidden="true"
                          style={{ position: "absolute", left: "-5000px" }}
                        >
                          <input
                            type="text"
                            value=""
                            name="b_d90b0fa4f4bc1cd0fea68f9d2_9bb8445736"
                            tabindex="-1"
                          />
                        </div>
                        <div className="clear">
                          <input
                            type="submit"
                            name="subscribe"
                            id="mc-embedded-subscribe"
                            className="button"
                            value="Subscribe"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </Box>
            </Grid>
          </Grid>
        </FixedWidthContainer>
      </Box>
    </>
  );
}
