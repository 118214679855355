import {
  Box,
  TextField,
  Grid2 as Grid,
  Typography,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import SynclisLogo from "../../../assets/images/logo.svg";
import { useState } from "react";
import FixedWidthContainer from "../../reuseable/FixedWidthContainer/FixedWidthContainer";
import JSConfetti from "js-confetti";
import AbsoluteCenter from "../../reuseable/AbsoluteCenter/AbsoluteCenter";
import axios from "axios";

export default function VideoSalesLetterPage() {
  const [lead, setLead] = useState({});
  const [page, setPage] = useState(0);
  const [displayEmptyInput, setDisplayEmptyInput] = useState(false);
  const [didNotQualify, setDidNotQualify] = useState(false);

  const updateLead = (e, altName) => {
    const { value, name } = e.target;
    const mName = name ? name : altName;
    setLead({
      ...lead,
      [mName]: typeof altName === "boolean" ? altName : value,
    });
  };

  const pages = [
    <LeadQualifier updateLead={updateLead} lead={lead} />,
    <GeneralInformation updateLead={updateLead} />,
    <BookCall />,
  ];

  const hasEmptyInput = () => {
    const isEmpty =
      !lead.areTheyInfluencer || !lead.engagement || !lead.followers;
    setDisplayEmptyInput(
      !lead.areTheyInfluencer || !lead.engagement || !lead.followers
    );

    return isEmpty;
  };

  const passedValidation = () => {
    const unqualified =
      !lead.areTheyInfluencer ||
      lead.areTheyInfluencer === "No" ||
      !lead.followers ||
      lead.followers === "0 to 119,999";

    setDidNotQualify(unqualified);

    return !unqualified;
  };

  const nextPage = async () => {
    if (hasEmptyInput()) {
      return;
    }

    if (!passedValidation()) {
      return;
    }
    if (page === pages.length - 1) {
      return;
    }

    if (page === pages.length - 2) {
      const jsConfetti = new JSConfetti();

      await axios({
        method: "POST",
        url: `https://api.imaginate.io/create-contact`,
        data: lead,
        headers: {
          "Content-Type": "application/json",
        },
      }).then((resp) => {
        if (resp.status >= resp.status) jsConfetti.addConfetti();
      });
    }

    if (page !== pages.length - 1) setPage(page + 1);
  };

  const lastPage = () => {
    if (page !== 0) setPage(page - 1);
  };

  const steps = ["Qualification", "General Information", "All done! 🎉"];

  if (didNotQualify) {
    return (
      <AbsoluteCenter>
        <Box maxWidth="320px">
          <Typography
            variant="h6"
            fontFamily='"Nunito", serif;'
            gutterBottom
            sx={{
              textAlign: "center",
              lineHeight: "32px",
              marginBottom: "2em",
              textTransform: "uppercase",
              color: "#555",
              letterSpacing: "2px",
            }}
          >
            you did not qualify. 😔
          </Typography>
          <Box mb="1.5em">
            Not to worry! We have an online school to help you learn more about
            growing your audience or business.
            <div>
              <b>At no cost to you.</b>
            </div>
          </Box>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              window.location = "https://www.skool.com/imaginate";
            }}
          >
            Learn more
          </Button>
        </Box>
      </AbsoluteCenter>
    );
  }

  return (
    <FixedWidthContainer>
      <Box minWidth="400px" p="2em">
        <Box textAlign="center" mb="3em">
          <img src={SynclisLogo} width="200px" />
        </Box>

        <Box mb="2em">
          <Stepper activeStep={page} alternativeLabel>
            {steps.map((label) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>

        {displayEmptyInput && (
          <Box
            border="1px solid #f55"
            color="#f55"
            backgroundColor="#fee"
            borderRadius="3px"
            p="1em"
            mb="1em"
          >
            There are some unanswered questions. Please answer all questions!
          </Box>
        )}

        {pages[page]}

        <Box textAlign="center" mt="1.5em">
          {page !== 0 && page !== 2 && (
            <Button style={{ minWidth: "200px" }} onClick={lastPage}>
              Back
            </Button>
          )}
          {page !== 2 && (
            <Button
              variant="contained"
              style={{ minWidth: "200px" }}
              onClick={nextPage}
            >
              Next
            </Button>
          )}
        </Box>
      </Box>
    </FixedWidthContainer>
  );
}

const LeadQualifier = ({ updateLead = () => {}, lead }) => {
  return (
    <Box textAlign="center">
      <Typography
        variant="h6"
        fontFamily='"Nunito", serif;'
        gutterBottom
        sx={{
          textAlign: "center",
          lineHeight: "32px",
          marginBottom: "2em",
          textTransform: "uppercase",
          color: "#555",
          letterSpacing: "2px",
        }}
      >
        qualification
      </Typography>

      <Grid container spacing={2}>
        <Grid size={12}>
          <Box mb="1em" textAlign="left">
            Are you a content creator, personal brand, or coach?
          </Box>
          <ToggleButtonGroup
            orientation="horizontal"
            value={lead.areTheyInfluencer}
            name="areTheyInfluencer"
            exclusive
            style={{ width: "100%" }}
            onChange={(e) => updateLead(e, "areTheyInfluencer")}
          >
            <ToggleButton value="No" aria-label="No" style={{ width: "50%" }}>
              No
            </ToggleButton>
            <ToggleButton value="Yes" aria-label="Yes" style={{ width: "50%" }}>
              Yes
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid size={12}>
          <Box mb="1em" mt="3em" textAlign="left">
            How many followers do you currently have? (Highest on one platform.)
          </Box>
          <Box sx={{ display: { md: "initial", lg: "none" } }}>
            <ToggleButtonGroup
              orientation="vertical"
              value={lead.followers}
              name="followers"
              exclusive
              style={{ width: "100%" }}
              onChange={(e) => updateLead(e, "followers")}
            >
              <ToggleButton value="0 to 119,999" aria-label="0 to 119,999">
                0 to 119,999
              </ToggleButton>
              <ToggleButton
                value="120,000 to 249,999"
                aria-label="120,000 to 249,999"
              >
                120,000 to 249,999
              </ToggleButton>
              <ToggleButton
                value="250,000 to 499,999"
                aria-label="250,000 to 499,999"
              >
                250,000 to 499,999
              </ToggleButton>
              <ToggleButton
                value="500,000 to 999,999"
                aria-label="500,000 to 999,999"
              >
                500,000 to 999,999
              </ToggleButton>
              <ToggleButton
                value="1,000,000 or more"
                aria-label="1,000,000 or more"
              >
                1,000,000 +
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "none", lg: "initial" },
            }}
          >
            <ToggleButtonGroup
              orientation="horizontal"
              value={lead.followers}
              name="followers"
              exclusive
              style={{ width: "100%" }}
              onChange={(e) => updateLead(e, "followers")}
            >
              <ToggleButton
                value="0 to 119,999"
                aria-label="0 to 119,999"
                style={{ width: "20%" }}
              >
                0 to 119,999
              </ToggleButton>
              <ToggleButton
                value="120,000 to 249,999"
                aria-label="120,000 to 249,999"
                style={{ width: "20%" }}
              >
                120,000 to 249,999
              </ToggleButton>
              <ToggleButton
                value="250,000 to 499,999"
                aria-label="250,000 to 499,999"
                style={{ width: "20%" }}
              >
                250,000 to 499,999
              </ToggleButton>
              <ToggleButton
                value="500,000 to 999,999"
                aria-label="500,000 to 999,999"
                style={{ width: "20%" }}
              >
                500,000 to 999,999
              </ToggleButton>
              <ToggleButton
                value="1,000,000 or more"
                aria-label="1,000,000 or more"
                style={{ width: "20%" }}
              >
                1,000,000 +
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Grid>
        <Grid size={12}>
          <Box mb="1em" mt="3em" textAlign="left">
            If you were to ask your followers to visit a website how many would
            visit?
          </Box>

          <Box sx={{ display: { md: "initial", lg: "none" } }}>
            <ToggleButtonGroup
              orientation="vertical"
              value={lead.engagement}
              exclusive
              style={{ width: "100%" }}
              onChange={(e) => updateLead(e, "engagement")}
            >
              <ToggleButton value="1 percent" aria-label="1 percent">
                Less than 1%
              </ToggleButton>
              <ToggleButton value="5 percent" aria-label="5 percent">
                Less than 5%
              </ToggleButton>
              <ToggleButton value="20 percent" aria-label="20 percent">
                Less than 10%
              </ToggleButton>
              <ToggleButton
                value="more than 20 percent"
                aria-label="more than 20 percent"
              >
                More than 20%
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "none", lg: "initial" },
            }}
          >
            <ToggleButtonGroup
              orientation="horizontal"
              value={lead.engagement}
              exclusive
              style={{ width: "100%" }}
              onChange={(e) => updateLead(e, "engagement")}
            >
              <ToggleButton
                value="1 percent"
                aria-label="1 percent"
                style={{ width: "25%" }}
              >
                Less than 1%
              </ToggleButton>
              <ToggleButton
                value="5 percent"
                aria-label="5 percent"
                style={{ width: "25%" }}
              >
                Less than 5%
              </ToggleButton>
              <ToggleButton
                value="20 percent"
                aria-label="20 percent"
                style={{ width: "25%" }}
              >
                Less than 10%
              </ToggleButton>
              <ToggleButton
                value="more than 20 percent"
                aria-label="more than 20 percent"
                style={{ width: "25%" }}
              >
                More than 20%
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const GeneralInformation = ({ updateLead = () => {} }) => {
  return (
    <>
      <Typography
        variant="h6"
        fontFamily='"Nunito", serif;'
        gutterBottom
        sx={{
          textAlign: "center",
          lineHeight: "32px",
          marginBottom: "2em",
          textTransform: "uppercase",
          color: "#555",
          letterSpacing: "2px",
        }}
      >
        General Information
      </Typography>

      <Grid container spacing={2}>
        <Grid size={6}>
          <TextField
            name="firstName"
            label="First Name"
            onChange={updateLead}
          />
        </Grid>
        <Grid size={6}>
          <TextField name="lastName" label="Last Name" onChange={updateLead} />
        </Grid>

        <Grid size={{ xs: 12, md: 4 }}>
          <TextField name="tiktok" label="🎵 TikTok" onChange={updateLead} />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <TextField
            name="instagram"
            label="📷 @Instagram"
            onChange={updateLead}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <TextField name="youtube" label="📹 YouTube" onChange={updateLead} />
        </Grid>

        <Grid size={12}>
          <TextField
            name="email"
            label="Email"
            type="email"
            onChange={updateLead}
          />
        </Grid>
        <Grid size={12}>
          <TextField
            name="phone"
            label="Phone Number"
            type="phone"
            onChange={updateLead}
          />
        </Grid>
      </Grid>
    </>
  );
};

const BookCall = ({ updateLead = () => {} }) => {
  return (
    <>
      <Typography
        variant="h6"
        fontFamily='"Nunito", serif;'
        gutterBottom
        sx={{
          textAlign: "center",
          lineHeight: "32px",
          marginBottom: "2em",
          textTransform: "uppercase",
          color: "#555",
          letterSpacing: "2px",
        }}
      >
        Book a call with us
      </Typography>

      <wistia-player media-id="lt1sp9vgp1"></wistia-player>

      <Box textAlign="center" mt="2em">
        <Button
          variant="contained"
          onClick={() => {
            window.location = "https://calendly.com/imaginate-io/30min";
          }}
          style={{ minWidth: "200px" }}
        >
          Book a call
        </Button>
      </Box>
    </>
  );
};
